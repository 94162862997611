import './NavBar.css';

import {
  faBars,
  faClose,
  faShoppingCart,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import React, { Component } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import LogoBlack from '@/assets/img/icons/Logo-Firma.svg';
import BasicMenu from '../Button/BasicMenu';
import { MensMenu, MenuItems, WomanMenu } from './MenuRoutes';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    color: `white`,
    background: `red`,
  },
}));

const Cart = () => {
  const quantity = useSelector((state) => state.cart.quantity);
  return (
    <StyledBadge badgeContent={quantity} key="cart-style-badge">
      <FontAwesomeIcon icon={faShoppingCart} />
    </StyledBadge>
  );
};

const dataSubMenu2 = [
  'LA PEREZ',
  'MIS RAICES',
  'VACIOS',
  'METEPEC-2022',
  'FORESTA',
];

const SubMenus = [MensMenu, WomanMenu];

class NavBar extends Component {
  state = {
    clicked: false,
    fixed: false,
    subMenu: false,
    subMenuCategory: 0,
    imageSrc: require('../../assets/img/Summer2022/LaPerez/5L3A0244.jpg'),
  };

  handleSubMenu = (id, active) => {
    if (id < SubMenus.length) {
      this.setState({ subMenuCategory: id, subMenu: active });
    } else {
      this.closeSubMenu();
    }
  };

  closeSubMenu = () => {
    this.setState({ subMenu: false });
  };

  hoverChangeImage = (id) => {
    this.setState({ imageSrc: id });
  };

  toggleMenu = () => {
    this.setState((prevState) => ({ clicked: !prevState.clicked }));
  };

  handleScroll = () => {
    this.setState({ fixed: window.scrollY !== 0 });
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { clicked, fixed, subMenu, subMenuCategory, imageSrc } = this.state;

    return (
      <React.Fragment>
        <header
          className={`${fixed ? 'fixed' : 'relative'} flex flex-col z-20 w-full pb-5`}
        >
          {/* Main Navigation */}
          <nav className="flex flex-row justify-between w-full z-30 bg-white">
            {/* Menu Toggle */}
            <div className="menu-icon-toggle" onClick={this.toggleMenu}>
              <FontAwesomeIcon icon={clicked ? faClose : faBars} />
            </div>

            {/* Primary Navigation */}
            <div className={`primary-nav ${clicked ? 'active' : ''} w-1/3`}>
              <ul className="flex flex-col md:flex-row gap-5 pt-2 h-full">
                {MenuItems.map((item) => (
                  <li key={item.title} className='flex items-center'>
                    <NavLink
                      to={item.path}
                      className={item.cName}
                      onMouseOver={() => this.handleSubMenu(item.subMenu, true)}
                      onClick={this.closeSubMenu}
                    >
                      {item.title}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>

            {/* Logo */}
            <div className="w-1/3 flex justify-center">
              <NavLink to="/">
                <img src={LogoBlack} alt="Brand Logo" width="90" />
              </NavLink>
            </div>

            {/* Secondary Navigation */}
            <ul className="flex flex-row gap-3 justify-end w-1/3">
              <li className="mx-2 cursor-pointer">
                <NavLink to="/Cart">
                  <Cart />
                </NavLink>
              </li>
              <li>
                <BasicMenu />
              </li>
            </ul>
          </nav>

          {/* Submenu */}
          <nav
            className={`${
              subMenu ? 'flex' : 'hidden'
            } flex-wrap w-full z-30`}
            onMouseLeave={this.closeSubMenu}
          >
            <div className="submenu w-1/2 md:w-1/3">
              <ul className="flex flex-col gap-5">
                {SubMenus[subMenuCategory].map((item) => (
                  <li key={item.info.title}>
                    <NavLink
                      to={item.path}
                      onMouseOver={() => this.hoverChangeImage(item.src)}
                      onClick={this.closeSubMenu}
                      className="cursor-pointer px-5 hover:underline-offset-1 hover:underline"
                    >
                      {item.info.title}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
            <div className="submenu w-1/2 md:w-1/3">
              <ul className="flex flex-col gap-5">
                {dataSubMenu2.map((item) => (
                  <li key={item} className="px-5 cursor-pointer hover:underline-offset-1 hover:underline">
                    <NavLink to="/laPerez">{item}</NavLink>
                  </li>
                ))}
              </ul>
            </div>
            <div className="submenu bg-blue-200 w-full md:w-1/3 overflow-hidden">
              <img
                className="w-full h-full object-cover object-top"
                src={imageSrc}
                alt="Submenu Preview"
              />
            </div>
          </nav>
        </header>
      </React.Fragment>
    );
  }
}

export default NavBar;
