import React, { useState } from 'react';
import { Box, Button, Container } from '@mui/material';
import VerticalNavBar from '../../components/Admin/NavBar/VerticalNavBar';
import ProductTable from '../../components/Admin/Tables/ProductTable';
import { useSnackbar } from '../../components/Alerts/SnackBarProvider';
import MobileAdminNavBar from '../../components/Admin/NavBar/MobileAdminNavBar';

const ProductAdminPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [existingProducts, setExistingProducts] = useState([]);
  const { showSnackbar } = useSnackbar();

  const handleSaveProduct = async (product) => {
    try {
      console.log('Saving product:', product);
      //const result = await createProduct(product);
      //console.log('Saved Product Response:', result);
      showSnackbar('¡Producto agregado de forma exitosa!', 'success');
    } catch (error) {
      console.error('Error saving product:', error);
      showSnackbar('Error al agregar el producto. Por favor, inténtelo de nuevo.', 'error');
    }
  };

  const handleExistingProducts = (data) => {
    setExistingProducts(data);
  };

  return (
    <section className="relative flex flex-col md:flex-row">
      <div className="hidden md:block">
        <VerticalNavBar />
      </div>
      <div className="block md:hidden">
        <MobileAdminNavBar />
      </div>
      <Container className="flex flex-col gap-5">
        <Box className="flex justify-center items-center gap-5 my-5">
          <Button onClick={() => setModalOpen(true)} variant="contained" color="primary" className="!rounded-full">
            Agregar Producto
          </Button>
        </Box>
        <Box>
          <ProductTable onSendExistingProducts={handleExistingProducts} />
        </Box>
      </Container>
    </section>
  );
};

export default ProductAdminPage;
