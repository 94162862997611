export const MenuItems = [
  {
    title: 'Hombre',
    path: '/Men',
    subMenu: 0,
    cName: 'nav-links',
  },
  {
    title: 'Mujer',
    path: '/Women',
    subMenu: 1,
    cName: 'nav-links',
  },
  {
    title: 'Nosotros',
    path: '/Nosotros',
    subMenu: 3,
    cName: 'nav-links',
  },
];

export const WomanMenu = [
  {
    id: 1,
    info: {
      title: 'Destacado',
      desc: 'Vestido Negro con olanes especiales ',
    },
    path: '/ProductList/Destacado',
    src: require('../../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_05.jpg'),
  },
  {
    id: 2,
    info: {
      title: 'Bolsas',
      desc: 'Vestido Negro con olanes especiales ',
    },
    path: '/ProductList/Bolsas',
    src: require('../../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_20.jpg'),
  },
  {
    id: 3,
    info: {
      title: 'Accesorios',
      desc: 'Vestido Negro con olanes especiales ',
    },
    path: '/ProductList/Accesorios',
    src: require('../../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_22.jpg'),
  },
  {
    id: 4,
    info: {
      title: 'Zapatos',
      desc: 'Vestido Negro con olanes especiales ',
    },
    path: '/ProductList/Zapatos',
    src: require('../../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_24.jpg'),
  },
  {
    id: 5,
    info: {
      title: 'Vestidos',
      desc: 'Vestido Negro con olanes especiales ',
    },
    path: '/ProductList/Vestidos',
    src: require('../../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_29.jpg'),
  },
  {
    id: 6,
    info: {
      title: 'Ready to Wear',
      desc: 'Vestido Negro con olanes especiales ',
    },
    path: '/ProductList/Ready-to-Wear',
    src: require('../../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_31.jpg'),
  },
];

export const MensMenu = [
  {
    id: 1,
    info: {
      title: 'Destacado',
      desc: 'Vestido Negro con olanes especiales ',
    },
    path: '/ProductList/Destacado',
    src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-1.jpg'),
  },
  {
    id: 2,
    info: {
      title: 'Pantalones',
      desc: 'Vestido Negro con olanes especiales ',
    },
    path: '/ProductList/Pantalones',
    src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-2.jpg'),
  },
  {
    id: 3,
    info: {
      title: 'Accesorios',
      desc: 'Vestido Negro con olanes especiales ',
    },
    path: '/ProductList/Accesorios',
    src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-3.jpg'),
  },
  {
    id: 4,
    info: {
      title: 'Zapatos',
      desc: 'Vestido Negro con olanes especiales ',
    },
    path: '/ProductList/Zapatos',
    src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-4.jpg'),
  },
  {
    id: 5,
    info: {
      title: 'Camisas',
      desc: 'Vestido Negro con olanes especiales ',
    },
    path: '/ProductList/Camisas',
    src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-5.jpg'),
  },
  {
    id: 6,
    info: {
      title: 'Ready to Wear',
      desc: 'Vestido Negro con olanes especiales ',
    },
    path: '/ProductList/Ready-to-Wear',
    src: require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-6.jpg'),
  },
];
