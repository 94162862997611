// components/WebPagesTable.tsx

import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { CircularProgress, Box, IconButton, Menu, MenuItem,Stack, Switch, Skeleton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { deleteWebSiteSlider, getWebPagesByCategory, updateWebPages, updateWebPagesStatusById } from '../../../services/webPage';
import { useSnackbar } from '../../Alerts/SnackBarProvider';
import WebPageDetailsModal from '../Modal/WebPages/DetailsWebPageModal';
import DeleteWebPageModal from '../Modal/WebPages/DeleteWebPageModal';
import { deleteFile } from '../../../services/gcpServices';
import { extractFolderAndFileName } from '../../../utils/ConvertApp';



const WebPagesTable = ({ category }) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentSlider,setCurrentSlider] = useState();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    fetchWebPages();
  }, [category]);


  const fetchWebPages = async () => {
    setLoading(true); // Start loading
    try {
      //const data = await getWebPagesByCategory(category);
      //setRows(data);
    } catch (error) {
      console.error('Failed to fetch web pages:', error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleModalClose = () => {
    setIsDeleteModalOpen(false);
    setIsEditModalOpen(false);
  };

  const handleSliderStatus = async (id, isActive) => {
    try {
      // Toggle the isActive status
      const response = await updateWebPagesStatusById({ id, isActive: !isActive });
      // Check if the response is valid
      if (response !== null && typeof response === 'object') {
        fetchWebPages();
      } else {
        console.error('Received invalid response:', response);
      }
    } catch (error) {
      console.error('Error updating slider status:', error);
    }
  };

  const handleDelete = async (webSlider) => {
    try {
      if(webSlider.id){
        const { folderName, fileName} = extractFolderAndFileName(webSlider.imgUrl);
        if(folderName && fileName){
          await deleteFile(folderName,fileName);
          await deleteWebSiteSlider(webSlider.id);
        }
        console.log('folderNmae:'+folderName+' fileNmae:'+fileName);
      }
      //fetchWebPages();
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error('Error deleting promotion:', error);
      //showSnackbar('Error deleting promotion', 'error');
    }
  };

  const handleEdit = (webSlider) => {
    setCurrentSlider(webSlider);
    setIsEditModalOpen(true);
  };

  

  const columns= [
    { field: 'id', headerName: 'ID', width: 50 },
    {
      field: 'imgUrl',
      headerName: 'Image',
      width: 150,
      renderCell: (params) => (
        <img src={params.value} alt="WebPage" width="100" />
      ),
    },
    { field: 'title', headerName: 'Title', width: 100 },
    { field: 'category', headerName: 'Category', width: 150 },
    { field: 'startDay', headerName: 'Start Day', width: 100 },
    { field: 'endDay', headerName: 'End Day', width: 100 },
    { field: 'isActive', 
      headerName: 'Active', 
      width: 100, 
      renderCell:(params) => {
        return(
            <>
            <Switch 
              onChange={() => handleSliderStatus(params.row.id,params.row.isActive)} 
              checked={params.row.isActive}
              color="success"
              />
            </>
          )
      } },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 150,
        renderCell: (params) => (
          <Stack direction="row" spacing={1}>
            <IconButton onClick={() => handleEdit(params.row)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => {
              setCurrentSlider(params.row);
              setIsDeleteModalOpen(true);
            }}>
              <DeleteIcon />
            </IconButton>
          </Stack>
        ),
      },
  ];

  return (
    <div style={{ height: 500, width: '100%' }}>
      {loading ? (
        <Skeleton variant="rectangular" height={600} />
      ) : (
        <DataGrid
          rows={rows}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 20]}
          pagination
        />
      )}

      {currentSlider && isDeleteModalOpen && (
        <DeleteWebPageModal
          open={isDeleteModalOpen}
          onClose={handleModalClose}
          onDelete={() => handleDelete(currentSlider)}
          product={currentSlider}
        />
      )}

      {currentSlider && isEditModalOpen &&(
          <WebPageDetailsModal
              open={isEditModalOpen}
              onClose={handleModalClose}
              onSave={async (updatedWebSlider) => {
                try {
                  if(updatedWebSlider.id){
                    await updateWebPages(updatedWebSlider.id, updatedWebSlider);
                    showSnackbar('Promotion updated successfully!', 'success');
                    fetchWebPages();
                    handleModalClose();
                  }else{
                    showSnackbar('Promoción sin Id!', 'error');
                  }
                } catch (error) {
                  console.error('Error updating promotion:', error);
                  showSnackbar('Error updating promotion', 'error');
                }
              }}
              product={currentSlider}
            />
      )}
      
    </div>
  );
};

export default WebPagesTable;
