// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import required modules
// import Swiper core and required modules
import { A11y, EffectFade, Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import LaPerezIndex from '@/pages/LaPerezPage';

const Container = styled.div`
  position: relative;
  height: 700px;
  overflow: hidden;
`;

const CarouselBox = styled(Swiper)`
  width: 100%;
  height: 700px;
  display: inline-grid;

  & .swiper-button-next,
  & .swiper-button-prev,
  & .swiper-pagination {
    color: white;
  }

  @media (max-width: 600px) {
    height: fit-content;
  }
`;

const Slider = styled.div`
  position: relative;
  height: 650px;
  width: 100vw;
  overflow: hidden;
  top: 0;

  @media (max-width: 600px) {
    height: 100%;
  }
`;

const TitleContainer = styled.h1`
  position: absolute;
  font-size: 8rem;
  line-height: 8rem;
  color: white;
  width: 80vw;
  max-width: 600px;
  z-index: 3;
  top: ${props => props.top || 0};
  left: ${props => props.left || '30%'};
  transition: all 1.5s ease-in;
  opacity: 0;
  &.active {
    opacity: 1;
  }

  @media (max-width: 600px) {
    font-size: 5rem;
    left: 5%;
    top: 10%;
  }
`;
const ImageContainer = styled.div`
  overflow: hidden;
  @media (max-width: 600px) {
    height: 300px;
  }
`;

const Image = styled.img`
  object-fit: cover;
  heigth: 100%;
  width: 100%;
  transition: all 1.5s ease-in;
  opacity: 0;

  &.active {
    opacity: 1;
  }
`;
const ContentContainer = styled.div`
  position: relative;

  @media (max-width: 600px) {
    height: fit-content;
  }
`;

const Description = styled.p`
  position: absolute;
  text-align: justify;
  color: white;
  transition: all 1s ease-in;
  overflow: hidden;
  width: ${props => props.txtwidth || '600px'};
  opacity: 0;
  left: 100%;
  &.active {
    opacity: 1;
    left: ${props => props.left || '0%'};
  }

  @media (max-width: 600px) {
    position: relative;
    width: 95%;
    height: fit-content;
    &.active {
      left: 0%;
    }
  }
`;

const HistorySlider = () => (
  <>
    <CarouselBox
      // install Swiper modules
      modules={[Navigation, Pagination, A11y, EffectFade]}
      spaceBetween={30}
      slidesPerView={1}
      effect={'fade'}
      navigation
      pagination={{
        clickable: true,
        type: 'fraction',
      }}
      onSwiper={swiper => {}}
      onSlideChange={() => {}}
      className="bg-black"
    >
      <SwiperSlide>
        {({ isActive }) => (
          <Slider id="slide-1" className="flex flex-wrap p-5 md:p-20">
            <TitleContainer
              top="20%"
              className={`${isActive ? 'active' : 'inactive'} top="10%" !text-[56px] !leading-10`}
            >
              Hilando mis raices e historia” 
                  “Cada puntada es una oportunidad para escribir tu propia historia
            </TitleContainer>
            <ImageContainer className="w-full md:w-2/5">
              <Image
                className={`${isActive ? 'active' : 'inactive'}`}
                src={require('../../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_24.jpg')}
                alt="Image-1"
              />
            </ImageContainer>
            <ContentContainer className="w-full md:w-3/5 flex justify-center items-end">
              <Description
                className={`${isActive ? 'active' : 'inactive'} px-5 md:px-10 flex justify-center items-start top-[60%]`}
              >
                Najjat Harb es una firma creada por  un deseñadora de moda Mexicana – Libanesa que nace para plasmar las emociones en diseños de ropa que a su vez, ayuden a sanar el alma y nuestras luchas interenas
Su nombre, de origen libanes, significa “superviviente de guerra”
              </Description>
            </ContentContainer>
          </Slider>
        )}
      </SwiperSlide>
      <SwiperSlide>
        {({ isActive }) => (
          <Slider id="slide-2" className="flex flex-wrap p-5 md:p-20">
            <TitleContainer
              className={`${isActive ? 'active' : 'inactive'} top="10%" `}
            >
              Arte y Costura
            </TitleContainer>
            <ImageContainer className="flex flex-col w-full md:w-1/2">
              <Image
                className={`${
                  isActive ? 'active' : 'inactive'
                } md:h-2/5 object-top`}
                src={require('../../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_31.jpg')}
                alt="Image-1"
              />
              <div className="md:h-3/5"></div>
            </ImageContainer>
            <ContentContainer className="w-full  md:w-1/2 flex justify-center items-center">
              <Description
                className={`${isActive ? 'active' : 'inactive'} px-5 md:px-10`}
              >
                “So strongly and metaphysically did I conceive of my situation
                then, that while earnestly watching his motions, I seemed
                distinctly to perceive that my own individuality was now merged
                in a joint stock company of two; that my free will had received
                a mortal wound; and that another&#39s mistake or misfortune
                might plunge innocent me into unmerited disaster and
                death.Therefore, I saw that here was a sort of interregnum in
                Providence; for its even-handed equity never could have so gross
                an injustice. And yet still further pondering—while I jerked him
                now and then from between the whale and ship, which would
                threaten to jam him—still further pondering, I say, I saw that
                this situation of mine was the precise situation of every mortal
                that breathes;
              </Description>
            </ContentContainer>
          </Slider>
        )}
      </SwiperSlide>
      <SwiperSlide>
        {({ isActive }) => (
          <Slider id="slide-3" className="flex flex-wrap p-5 md:p-20">
            <TitleContainer
              top="60%"
              className={`${isActive ? 'active' : 'inactive'} top="10%" `}
            >
              Libertad
            </TitleContainer>
            <ImageContainer className="w-full md:w-2/5">
              <Image
                className={`${isActive ? 'active' : 'inactive'}`}
                src={require('../../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_28.jpg')}
                alt="Image-1"
              />
            </ImageContainer>
            <ContentContainer className="w-full md:w-3/5 flex justify-center items-start">
              <Description
                className={`${isActive ? 'active' : 'inactive'} px-5 md:px-10`}
              >
                “So strongly and metaphysically did I conceive of my situation
                then, that while earnestly watching his motions, I seemed
                distinctly to perceive that my own individuality was now merged
                in a joint stock company of two; that my free will had received
                a mortal wound; and that anothers mistake or misfortune might
                plunge innocent me into unmerited disaster and death.Therefore,
                I saw that here was a sort of interregnum in Providence; for its
                even-handed equity never could have so gross an injustice. And
                yet still further pondering—while I jerked him now and then from
                between the whale and ship, which would threaten to jam
                him—still further pondering, I say, I saw that this situation of
                mine was the precise situation of every mortal that breathes;
                only, in most cases, he, one way or other, has this Siamese
                connexion with a plurality of other mortals.”
              </Description>
            </ContentContainer>
          </Slider>
        )}
      </SwiperSlide>
      <SwiperSlide>
        {({ isActive }) => (
          <Slider id="slide-4" className="flex flex-wrap p-5 md:p-20 gap-10">
            <TitleContainer
              top="20%"
              left="10%"
              className={`${isActive ? 'active' : 'inactive'} top="10%" `}
            >
              Hilando Mis Raíces
            </TitleContainer>
            <ImageContainer className="w-full md:w-full md:h-60">
              <Image
                className={`${isActive ? 'active' : 'inactive'}`}
                src={require('../../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_28.jpg')}
                alt="Image-1"
              />
            </ImageContainer>
            <ContentContainer className="w-full md:w-full flex justify-center items-start">
              <div className="md:w-1/2  hidden md:inline-block"></div>
              <Description
                left="45%"
                className={`${
                  isActive ? 'active' : 'inactive'
                } w-full md:w-1/2 px-5 md:px-10`}
              >
                “So strongly and metaphysically did I conceive of my situation
                then, that while earnestly watching his motions, I seemed
                distinctly to perceive that my own individuality was now merged
                in a joint stock company of two; that my free will had received
                a mortal wound; and that anothers mistake or misfortune might
                plunge innocent me into unmerited disaster and death.Therefore,
                I saw that here was a sort of interregnum in Providence; for its
                even-handed equity never could have so gross an injustice. And
                yet still further pondering—while I jerked him now and then from
                between the whale and ship, which would threaten to jam
                him—still further pondering, I say, I saw that this situation of
                mine was the precise situation of every mortal that breathes;
              </Description>
            </ContentContainer>
          </Slider>
        )}
      </SwiperSlide>
    </CarouselBox>
  </>
);

export default HistorySlider;
