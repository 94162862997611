import React, { useState, useEffect } from 'react';
import {
  Card,
  CardMedia,
  Box,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  TextField,
  FormControlLabel,
  Skeleton,
  DialogTitle,
  Switch,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {
  Title,
  Category,
  CalendarToday,
  CheckCircle,
  Cancel,
} from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { uploadImage } from '../../../../services/gcpServices';

const WebPageDetailsModal = ({ open, onClose, onSave, product }) => {
  const [editMode, setEditMode] = useState(false);
  const [updatedProduct, setUpdatedProduct] = useState(product);
  const [selectedCategory, setSelectedCategory] = useState(product.category);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [imageFile, setImageFile] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedProduct((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleCategoryChange = (category, checked) => {
    if (checked) {
      setSelectedCategory(category);
      setUpdatedProduct((prevState) => ({ ...prevState, category }));
    } else {
      setSelectedCategory(null);
    }
  };

  const handleDateChange = (name, newValue) => {
    setUpdatedProduct((prevState) => ({
      ...prevState,
      [name]: newValue ? newValue.toISOString() : prevState[name],
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setUpdatedProduct((prevState) => ({ ...prevState, imgUrl: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleActiveToggle = () => {
    setUpdatedProduct((prevState) => ({ ...prevState, isActive: !prevState.isActive }));
  };

  const handleSave = async () => {
    try {
      let imgUrl = '';
      if (imageFile) {
        imgUrl = await uploadImage(imageFile, 'Products');
      }
      const updatedProductData = {
        ...updatedProduct,
        imgUrl: imgUrl || updatedProduct.imgUrl,
      };
      await onSave(updatedProductData);
      setEditMode(false);
    } catch (error) {
      console.error('Failed to save the product:', error);
    }
  };

  const handleUpdateConfirmation = () => {
    setConfirmDialogOpen(true);
  };

  const handleEditToggle = () => {
    setEditMode(true);
  };

  useEffect(() => {
    if (product) {
      setUpdatedProduct(product);
    }
  }, [product]);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogContent>
          <Card>
            <Skeleton
              variant="rectangular"
              width="60%"
              height={200}
              animation="wave"
              sx={{ borderRadius: '4px' }}
            />
            {updatedProduct.imgUrl && (
              <CardMedia
                component="img"
                sx={{
                  width: 'fit-content%',
                  height: 'auto',
                  display: 'block',
                  margin: 'auto',
                  objectFit: 'fill',
                }}
                image={updatedProduct.imgUrl}
                title={updatedProduct.title}
              />
            )}
          </Card>

          {editMode && (
            <Box className="relative">
              <Box sx={{ position: 'absolute', bottom: 8, right: 8 }}>
                <input
                  accept="image/*"
                  type="file"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                  id="upload-image"
                />
                <label htmlFor="upload-image">
                  <IconButton
                    component="span"
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      boxShadow: 2,
                      '&:hover': {
                        boxShadow: 4,
                      },
                      padding: 1,
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </label>
              </Box>
            </Box>
          )}

          <Box sx={{ mt: 2 }}>
            {editMode ? (
              <>
                <TextField
                  label="Título"
                  name="title"
                  fullWidth
                  value={updatedProduct.title}
                  onChange={handleInputChange}
                  sx={{ mb: 2 }}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box className="flex justify-between items-center">
                    <DatePicker
                      label="Fecha Inicio"
                      value={updatedProduct.startDay ? dayjs(updatedProduct.startDay) : null}
                      onChange={(newValue) => handleDateChange('startDay', newValue)}
                      sx={{ mb: 2 }}
                    />
                    <DatePicker
                      label="Fecha Termino"
                      value={updatedProduct.endDay ? dayjs(updatedProduct.endDay) : null}
                      onChange={(newValue) => handleDateChange('endDay', newValue)}
                      sx={{ mb: 2 }}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={handleActiveToggle}
                          checked={updatedProduct.isActive}
                          color="success"
                        />
                      }
                      label="Promoción Activa"
                    />
                  </Box>
                </LocalizationProvider>
                <Box className="flex flex-wrap gap-5 justify-center my-5">
                  {/* Options rendering logic here */}
                </Box>
              </>
            ) : (
              <Box className="flex flex-wrap gap-5 p-4 bg-gray-100 rounded-lg shadow-md">
                {/* Display product details */}
              </Box>
            )}
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button onClick={onClose} color="error" variant="contained">
              Cerrar
            </Button>
            {editMode ? (
              <Box className="flex flex-wrap gap-5">
                <Button onClick={() => setEditMode(false)} color="error" variant="contained">
                  Cancelar
                </Button>
                <Button onClick={handleUpdateConfirmation} color="primary" variant="contained">
                  Guardar
                </Button>
              </Box>
            ) : (
              <Button onClick={handleEditToggle} color="primary" variant="contained">
                Editar
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default WebPageDetailsModal;
