import React from 'react'

const DashboardCard = (info) => {
  return (
    <>
      <div className='flex flex-col justify-center items-center gap-2 sm:gap-5 shadow-2xl w-[100px] h-[100px] sm:w-[150px] sm:h-[150px] rounded-xl cursor-pointer hover:scale-110 transition-all'>
        {/* Icon */}
        <div className='w-8 h-8 sm:w-12 sm:h-12'>
          <img className='object-fit w-full h-full' src={info.icon} alt="" />
        </div>

        {/* Title (hidden on small screens) */}
        <div className="title hidden sm:block">
          <h5 className='text-xs sm:text-sm text-black text-center'>{info.title}</h5>
        </div>
      </div>
    </>
  )
}

export default DashboardCard;