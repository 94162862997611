export const convertToMB = (capacity) => {
    if (capacity >= 1024 * 1024) {
      return `${(capacity / (1024 * 1024))} TB`;
    } else if (capacity >= 1024) {
      return `${(capacity / 1024)} GB`;
    } else {
      return `${capacity} MB`;
    }
  };

  export const formatPrice = (price) => {
    return price.toLocaleString('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  export function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  export function stringAvatar(name) {
    const nameParts = name.split(' ');
  
    let initials;
    if (nameParts.length === 1) {
      // If only one part of the name, take the first letter
      initials = nameParts[0][0];
    } else {
      // Otherwise, take the first letter of the first two parts
      initials = `${nameParts[0][0]}${nameParts[1][0]}`;
    }
  
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: initials.toUpperCase(), // Ensure initials are uppercase
    };
  }

  export function extractFolderAndFileName (url){
    // Remove the domain part from the URL, and extract the path
    const urlPath = new URL(url).pathname;
  
    // Split the path into its parts, assuming a structure like /<bucket>/<folder>/<filename>
    const pathParts = urlPath.split('/').filter(part => part); // Removes empty parts (like leading "/")
  
    if (pathParts.length < 2) {
      throw new Error('Invalid URL structure: URL should contain both folder and file name.');
    }
  
    // The last part will be the file name
    const fileName = pathParts.pop();
  
    // The remaining parts make up the folder structure
    const folderName = pathParts.pop();
  
    return { folderName, fileName };
  };