import { publicRequest, userRequestByParams } from "./requestMethods";

class WebPageServiceError extends Error {
  constructor(message) {
    super(message);
    this.name = 'WebPageServiceError';
  }
}

export const getAllWebPage = async () => {
  try {
    const response = await publicRequest.get('/webpages');
    return response.data;
  } catch (error) {
    console.error('Error fetching webpages:', error);
    throw error;
  }
};

export const createWebSlider = async (webSlider) => {
  try {
    const response = await publicRequest.post('/webpages', webSlider);
    return response.data;
  } catch (error) {
    console.error('Error creating web slider:', error);
    throw error;
  }
};

export const updateWebPages = async (id, webSlider) => {
  try {
    const response = await publicRequest.put(`/webpages/${id}`, webSlider);
    return response.data;
  } catch (error) {
    console.error('Error updating web page:', error);
    throw error;
  }
};

export const updateWebPagesStatusById = async ({ id, isActive }) => {
  try {
    const params = { isActive };
    const response = await userRequestByParams(params).put(`/webpages/update-status/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error updating web page status:', error);
    throw error;
  }
};

export const getWebPagesByCategory = async (category) => {
  try {
    const response = await publicRequest.get('/webpages/category', {
      headers: {
        category,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching web pages:', error);
    throw error;
  }
};

export const deleteWebSiteSlider = async (id) => {
  try {
    const response = await publicRequest.delete(`/webpages/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting web page:', error);
    throw error;
  }
};
