import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: end;
`;

const ImageContainer = styled.div`
  height: 600px;
  width: 350vw;
  max-width: 450px;
  position: relative;
  overflow: hidden;

  @media (max-width: 600px) {
    width: 100vw;
  }
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  heigth: 100%;
  object-fit: filled;
  opacity: 0;
  transition: all 0.5s ease-in-out;

  &.loaded {
    opacity: 1;
  }
`;

const PaginationContainer = styled.ul`
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    width: 100vw;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const PaginationItem = styled.li`
  cursor: pointer;
  color:white;
  margin-left: 0.5rem;
  &:hover {
    text-decoration: underline;
  }

  &.active {
    text-decoration: underline;
  }
`;

const VerticalCarouselType2 = ({ photos }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(photos[0]);
  const [loaded, setLoaded] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      selectNewImage(selectedIndex, photos);
    }, 5000);

    return () => clearInterval(interval);
  });

  const selectNewImage = (index, photos, next = true) => {
    setLoaded(false);
    setTimeout(() => {
      const condition = next
        ? selectedIndex < photos.length - 1
        : selectedIndex > 0;
      const nextIndex = next
        ? condition
          ? selectedIndex + 1
          : 0
        : condition
        ? selectedIndex - 1
        : photos.length - 1;
      setSelectedImage(photos[nextIndex]);
      setSelectedIndex(nextIndex);
    }, 500);
  };

  const selectImage = index => {
    setLoaded(false);
    setTimeout(() => {
      setSelectedImage(photos[index]);
      setSelectedIndex(index);
    }, 500);
  };

  /*const previous = () =>{
        selectNewImage(selectedIndex,photos,false);
    }

    const next = () =>{
        selectNewImage(selectedIndex,photos);
    }*/

  return (
    <Container className='flex gap-2 items-end'>
      <PaginationContainer>
        {photos.map((item, index) => (
          <PaginationItem
            className={selectedIndex === index ? 'active' : ''}
            onClick={() => selectImage(index)}
            key={index}
          >
            {index < 9 ? `0${index+ 1}` : index + 1}
          </PaginationItem>
        ))}
      </PaginationContainer>
      <ImageContainer>
        <Image
          src={selectedImage.src}
          key={selectedIndex}
          alt={selectedImage.info.desc}
          className={loaded ? 'loaded' : ''}
          onLoad={() => setLoaded(true)}
        />
      </ImageContainer>
    </Container>
  );
};

export default VerticalCarouselType2;
