import React, { useState } from 'react';
import { Modal, Box, Button, TextField, Checkbox, FormControlLabel, IconButton, Card, CardMedia } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { uploadImage } from '../../../../services/gcpServices'; // Ensure this function is correctly configured
import CheckboxTile from '../../Cards/CheckboxTile';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  maxHeight: 700,
  overflow: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const AddSliderModal = ({ open, onClose, onSave }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('Slider Principal');

  const formik = useFormik({
    initialValues: {
      id: 0,
      imgUrl: '',
      title: '',
      category: 'Slider Principal',
      startDay: new Date(),
      endDay: new Date(),
      isActive: true,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Title is required'),
      category: Yup.string().required('Category is required'),
      startDay: Yup.date().required('Start day is required'),
      endDay: Yup.date().required('End day is required').min(Yup.ref('startDay'), 'End day must be after start day'),
      isActive: Yup.boolean(),
    }),
    onSubmit: async (values) => {
      if (imageFile) {
        try {
          const url = await uploadImage(imageFile, 'WebSite');
          onSave({ ...values, imgUrl: url, createdAt: new Date(), updatedAt: new Date() });
          handleClose();
        } catch (error) {
          console.error('Failed to upload image:', error);
        }
      } else {
        onSave({ ...values, imgUrl: '', createdAt: new Date(), updatedAt: new Date() });
        handleClose();
      }
    },
  });

  const handleCategoryChange = (category, checked) => {
    if (checked) {
      setSelectedCategory(category);
      formik.setFieldValue('category', category);
    }
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => setImagePreview(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleClose = () => {
    formik.resetForm();
    setImageFile(null);
    setImagePreview(null);
    setSelectedCategory('Slider Principal');
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <form onSubmit={formik.handleSubmit}>
          <Box className="relative">
            <Card>
              {imagePreview ? (
                <CardMedia component="img" sx={{ width: 'fit-content', height: 'auto', maxHeight: 400, display: 'block', margin: 'auto', objectFit: 'contain' }} image={imagePreview} title="Uploaded Image" />
              ) : (
                <div className="flex justify-center items-center">
                  <img src={'../assets/icons/DashboardAdmin/folder.png'} alt="Fallback Icon" style={{ width: 'auto', height: 'auto', maxHeight: '200px', padding: '1em', objectFit: 'contain' }} />
                </div>
              )}
            </Card>
            <Box sx={{ position: 'absolute', bottom: 8, right: 8 }}>
              <input accept="image/*" type="file" onChange={handleImageChange} style={{ display: 'none' }} id="upload-image" />
              <label htmlFor="upload-image">
                <IconButton component="span" sx={{ backgroundColor: 'white', borderRadius: '50%', boxShadow: 2, '&:hover': { boxShadow: 4 }, padding: 1 }}>
                  <EditIcon />
                </IconButton>
              </label>
            </Box>
          </Box>
          <TextField fullWidth margin="normal" id="title" name="title" label="Title" value={formik.values.title} onChange={formik.handleChange} error={formik.touched.title && Boolean(formik.errors.title)} helperText={formik.touched.title && formik.errors.title} />
          <FormControlLabel control={<Checkbox checked={formik.values.isActive} onChange={formik.handleChange} name="isActive" />} label="Active Promotion" />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box className="flex justify-between items-center">
              <DatePicker label="Start Date" value={formik.values.startDay ? dayjs(formik.values.startDay) : null} onChange={(newValue) => formik.setFieldValue('startDay', newValue?.toDate())} sx={{ mb: 2 }} />
              <DatePicker label="End Date" value={formik.values.endDay ? dayjs(formik.values.endDay) : null} onChange={(newValue) => formik.setFieldValue('endDay', newValue?.toDate())} sx={{ mb: 2 }} />
            </Box>
          </LocalizationProvider>
          <Box className="flex flex-wrap gap-5 justify-center">
            <Button onClick={handleClose} variant="contained" color="error" className="mt-4">
              Cancelar
            </Button>
            <Button type="submit" variant="contained" color="primary" className="mt-4">
              Agregar Slider
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AddSliderModal;
