import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { A11y, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';

// Styled Components
const CarouselBox = styled(Swiper)`
  width: 100%;
  height: 600px;
  display: inline-grid;
  position: relative;
  overflow: hidden;

  & .swiper-slide {
    margin: 0 0px;
    padding: 0;
  }

  & .swiper-button-next,
  & .swiper-button-prev {
    color: black;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }

  & .swiper-button-next {
    right: -50px;
  }

  & .swiper-button-prev {
    left: -20px;
  }

  & .swiper-button-next:hover,
  & .swiper-button-prev:hover {
    color: rgb(105, 105, 105);
  }

  & .swiper-pagination {
    color: black;
    position: absolute;
    bottom: 20px;
    z-index: 10;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
  }

  & .swiper-pagination-bullet {
    background-color: black;
  }

  & .swiper-pagination-bullet-active {
    background-color: #ff5722;
  }

  @media (max-width: 600px) {
    height: fit-content;
    padding: 0 10px;
  }
`;

const Slider = styled.div`
  position: relative;
  height: 650px;
  width: 100vw;
  overflow: hidden;

  @media (max-width: 600px) {
    height: 100%;
  }
`;

const CustomArrow = ({ direction, onClick }) => (
  <button
    className={`absolute top-1/2 transform -translate-y-1/2 z-10 bg-transparent border-none text-black text-4xl cursor-pointer 
      ${direction === 'left' ? 'left-[-50px]' : 'right-[-50px]'}`}
    onClick={onClick}
  >
    {direction === 'left' ? '←' : '→'}
  </button>
);

const VideoSlider = () => {
  const [videoList, setVideoList] = useState([]);
  const swiperRef = useRef(null);

  useEffect(() => {
    // Example video list with YouTube video IDs
    const exampleVideos = [
      { videoUrl: 'yycVNcishrE', title: 'Fashion Week' },  // Rickroll video
      { videoUrl: 'yYI9MiM1dtk', title: 'Summer' },  // YouTube video example
      { videoUrl: 'GFAVL4OLjzY', title: 'Style' },  // Gangnam Style
      { videoUrl: 'fJ9rUzIMcZQ', title: 'Bohemian Rhapsody' }   // Bohemian Rhapsody
    ];
    setVideoList(exampleVideos);
  }, []);

  return (
    <Box className="flex flex-row w-full">
      <CustomArrow direction="left" onClick={() => swiperRef.current.swiper.slidePrev()} />
      
      <CarouselBox
        modules={[Navigation, Pagination, A11y]}
        loop={true}
        navigation={true} // Uses default navigation (you can remove this line if using custom buttons)
        slidesPerView={1}
        spaceBetween={10}
        initialSlide={0}
        centeredSlides={false}
        ref={swiperRef}
        breakpoints={{
          320: { slidesPerView: 1, spaceBetween: 0 },
          480: { slidesPerView: 1, spaceBetween: 10 },
          768: { slidesPerView: 2, spaceBetween: 0 },
          1024: { slidesPerView: 3, spaceBetween: 30 },
          1280: { slidesPerView: 4, spaceBetween: 40 },
        }}
      >
        {videoList.length > 0 ? (
          videoList.map((item, index) => (
            <SwiperSlide key={`slide-${index}`}>
              <Slider id={`slide-${index}`} className="flex flex-wrap justify-center items-center">
                {/* MUI Card to display YouTube Video Thumbnail */}
                {item.videoUrl ? (
                  <Card sx={{ maxWidth: 345, cursor: 'pointer' }} onClick={() => window.open(`https://www.youtube.com/watch?v=${item.videoUrl}`, '_blank')}>
                    <CardMedia
                      component="img"
                      height="140"
                      image={`https://img.youtube.com/vi/${item.videoUrl}/0.jpg`}
                      alt={item.title}
                    />
                    <CardContent>
                      <Typography variant="h6" component="div" noWrap>
                        {item.title}
                      </Typography>
                    </CardContent>
                  </Card>
                ) : (
                  <div className="text-gray-500 text-sm">No video available</div>
                )}
              </Slider>
            </SwiperSlide>
          ))
        ) : (
          <div className="text-center text-gray-500 py-5">No videos available</div>
        )}
      </CarouselBox>
      
      <CustomArrow direction="right" onClick={() => swiperRef.current.swiper.slideNext()} />
    </Box>
  );
};

export default VideoSlider;
