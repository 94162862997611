import { useState, useEffect } from 'react';

const useImageValidation = (imageUrl) => {
  const [imgUrlIsValid, setImgUrlIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkUrl = () => {
      const img = new Image();

      img.onload = () => {
        setImgUrlIsValid(true);  // Image successfully loaded
        setIsLoading(false);
      };

      img.onerror = () => {
        setImgUrlIsValid(false); // Image failed to load
        setIsLoading(false);
        console.log("Error loading image");
      };

      img.src = imageUrl;  // Start loading the image
    };

    if (imageUrl) {
      checkUrl();
    }

  }, [imageUrl]);

  return { imgUrlIsValid, isLoading };
};

export const isValidImageUrl = async (imageUrl) => {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = () => {
      resolve(true); // Image successfully loaded
    };

    img.onerror = () => {
      console.log("Error loading image");
      resolve(false); // Image failed to load
    };

    img.src = imageUrl; // Start loading the image
  });
};

export default useImageValidation;