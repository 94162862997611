import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import AnimateLogo from '@/components/AnimateLogo';
import MagazinesBox from '@/components/MagazineBox/MagazineBox';
import NavBar from '@/components/NavBar/NavBar';
import Newsletter from '@/components/Newsletter';
import VerticalCarousel from '@/components/VerticalCarousel';
import { CollectionWinter } from '@/Data/data';
import { Box, Typography } from '@mui/material';
import VerticalCarouselType2 from '../components/Carousel/VerticalCarouselType2';
import LogoBlack from '@/assets/img/icons/Logo-Firma.svg';
import LanguageIcon from '@mui/icons-material/Language';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LoopIcon from '@mui/icons-material/Loop';

const Container = styled.section`
  position: relative;
`;

const MediaContainer = styled.div`
  height: auto;
`;

const ContainerStand1 = styled.section`
  position: relative;
  background-color: #000000;

  & * {
    color: white;
  }

  & .media-article {
    height: 130vh;
    width: 70vw;
    max-width: 950px;
    overflow: hidden;
  }

  & .artist-logo-nx {
    position: sticky;
    top: 60%;
  }

  @media (max-width: 600px) {
    & .media-article {
      height: fit-content;
      width: 100vw;
    }
  }
`;

const ContainerStand2 = styled.section`
  position: relative;
  background-color: #f7f4e9;
`;

const ContainerStand3 = styled.section`
  position: relative;
`;

const ContainerStand4 = styled.section`
  position: relative;
  background-color: #ffeeee;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 1.2rem;
`;

const SubTitle = styled.h3`
    text-align:center;
    font-size:1.2rem;
    cursor:pointer;

    &:hover{
    text-decoration: underline;
    }

}

`;

const ImageContainer = styled.div`
  width: 70vw;
  max-width: 950px;
  @media (max-width: 600px) {
    width: 100vw;
  }
`;

const Image = styled.img`
  heigth: 100%;
  width: 100%;
  object-fit: cover;
`;

const FirmContainer = styled.div`
  & h2 {
    font-size: 3rem;
  }

  & h3 {
    font-size: 2rem;
    line-height: 1rem;
    padding-left: 10rem;
    cursor: default;
  }

  & h3:hover {
    text-decoration: none;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  height: 300px;
`;

const IconCardWrapper = styled.div`
  @apply flex flex-col justify-center items-center bg-gray-100 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out;
  max-width: 200px;
`;

const IconWrapper = styled.div`
  @apply mb-2;
  svg {
    font-size: 3rem;
    color: black; /* Tailwind blue-500 */
  }
`;

const TitleBox= styled.h3`
  @apply text-xl font-semibold text-gray-800;
`;

const Description = styled.p`
  @apply text-sm text-gray-400;
`;

const IconCard = ({ icon: Icon, title, description }) => {
  return (
    <IconCardWrapper className='flex flex-col items-center justify-center w-1/2'>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <TitleBox className='text-xl font-bold'>{title}</TitleBox>
      <Description className='text-gray-500 text-center'>{description}</Description>
    </IconCardWrapper>
  );
};

const VideoContainer = styled.div``;

const FrontPage = () => {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        // console.error('Error attempting to play', error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <>
      <NavBar />
      <Container className='bg-black flex flex-wrap h-[850px] items-center'>
        <Box className="w-5/12 h-full flex  justify-center items-end">
          <VerticalCarouselType2 photos={CollectionWinter}/>
        </Box>
        <Box className="flex flex-col gap-10 w-7/12 mt-10 h-full py-32">
          <Title className='!text-[85px] leading-[6rem] text-white !text-left w-10/12 grow'>
            Discover Fashion New Spring Collection
          </Title>
          <p className='text-white font-[Didot] text-justify w-10/12'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <div className="relative inline-block">
            <button className="text-white border-[0.5px] border-white py-5 px-8 rounded-full bg-transparent transform rotate-12 transition duration-300 ease-in-out hover:bg-white hover:text-black active:bg-gray-300">
              <span className="transform -rotate-12 inline-block">Comprar Ahora</span>
            </button>
          </div>

        </Box>
      </Container>
      <Container className='bg-[#EDEFF3] flex py-10'>
        <Box className='flex  justify-center items-center  gap-10 flex-col w-1/2'>
          <Title  className='!text-[95px] italic' >
            About Us
          </Title>
          <div className="!w-8/12 !h-[800px]">
            <Image
              className='!h-full'
              src={require('../assets/img/Summer2022/LaPerez/5L3A0626.jpg')}
              alt="img"
            />
        </div>
        </Box>
        <Box className='flex flex-col justify-cente items-center w-1/2'>
          <p className='text-2xl  text-gray-700 font-[Didot] text-justify w-10/12 grow pt-16'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <div className="media-article !w-11/12 h-[450px] overflow-hidden p-10 mb-16">
            <Image
              className='object-center object-cover !h-full'
              src={require('../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_29.jpg')}
              alt="img"
            />
        </div>
        </Box>
      </Container>
      <Container className='bg-[#EDEFF3] flex flex-col p-16'>
        <Box className='flex justify-start items-start pl-16'>
          <Title  className='!text-[95px] text-left py-10' >
              New Collections
            </Title>
        </Box>
        <Box className='flex gap-5 w-full justify-center items-end'>
          <Box className='flex flex-col gap-2 justify-start'>
            <div className="media-article !w-[450px] h-[750px] overflow-hidden">
                <Image
                  className='object-center object-cover !h-full'
                  src={require('../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_50.jpg')}
                  alt="img"
                />
            </div>
            <p className='text-2xl  text-gray-700 font-[Didot] text-justify grow'>
              New Arrivals
            </p>
        </Box>
        <Box className='flex flex-col gap-2 justify-start'>
            <div className="media-article !w-[450px] h-[450px] overflow-hidden">
                <Image
                  className='object-center object-cover !h-full'
                  src={require('../assets/img/Summer2022/LaPerez/5L3A0225.jpg')}
                  alt="img"
                />
            </div>
            <p className='text-2xl  text-gray-700 font-[Didot] text-justify grow'>
              Mens Wear
            </p>
        </Box>
        <Box className='flex flex-col gap-2 justify-start'>
            <div className="media-article !w-[450px] h-[750px] overflow-hidden">
                <Image
                  className='object-center object-cover !h-full'
                  src={require('../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_31.jpg')}
                  alt="img"
                />
            </div>
            <p className='text-2xl  text-gray-700 font-[Didot] text-justify grow'>
              Accesories
            </p>
        </Box>

        </Box>
      </Container>
      <Container className='bg-[#EDEFF3] flex flex-col p-16'>
        <Box className='flex justify-center items-start pl-16'>
          <Title  className='!text-[95px] text-left py-10 uppercase' >
              Spring Summer 2024 
            </Title>
        </Box>
        <Box className='p-20'>
          <MediaContainer>
            <video
              src={require('../assets/img/Video/HMR 21-05-05.mp4')}
              type="video/mp4"
              ref={videoEl}
              autoPlay
              controls
              preload="auto"
              loop
              muted
              width="100%"
              webkit-playsinline
              playsinline
            />
          </MediaContainer>
        </Box>
      </Container>
      <Container className='bg-[#EDEFF3] flex py-10'>
      <Box className='flex flex-col w-1/2 justify-center items-center'>
            <div className="media-article !w-[450px] overflow-hidden">
              <img src={LogoBlack} alt="" width="100%" />
            </div>
            <div className="flex flex-wrap gap-20 justify-center mt-8">
              <IconCard
                icon={LanguageIcon}
                title="Envio Global"
                description="Explore different languages and improve your skills."
              />
              <IconCard
                icon={StarBorderIcon}
                title="Top Brand"
                description="Keep track of your favorite items."
              />
              <IconCard
                icon={LocalShippingIcon}
                title="Envios"
                description="Fast and reliable shipping options."
              />
              <IconCard
                icon={LoopIcon}
                title="Retornos"
                description="Easy returns and exchanges."
              />
            </div>
        </Box>
        <Box className='flex w-1/2 justify-center relative'>
          <div className="relative media-article !w-10/12 h-[750px] overflow-hidden top-20">
                <Image
                  className='object-center object-cover !h-full'
                  src={require('../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_41.jpg')}
                  alt="img"
                />
            </div>
        </Box>

      </Container>
      <Container className='py-16'>
       <MagazinesBox></MagazinesBox>
      </Container>
      <Newsletter></Newsletter>
    </>
  );
};

export default FrontPage;
