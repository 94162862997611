import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import AddImageCarousel from '@/components/ImageCarousel/AddImageCarousel.jsx';
import AdminNavbar from '@/components/NavBar/AdminNavbar';
import { getProduct } from '@/services/products';

import InputWithEdit from '../Input/InputWithEdit';

const ProductDetail = () => {
  const [prodruct, setProduct] = useState();
  const id = 'Visitr';

  useEffect(() => {
    try {
      const getProductById = async () => {
        const response = await getProduct(id);
        const data = response.data;
        setProduct(data);
      };

      getProductById();
    } catch (err) {
      console.log(err);
    }
  }, [id]);

  return (
    <>
      <AdminNavbar />
      <div className="flex flex-wrap gap-5 justify-center align-middle content-center">
        <div className="w-11/12 md:w-5/12">
          <AddImageCarousel></AddImageCarousel>
        </div>
        <div className="w-11/12 md:w-5/12">
          <InputWithEdit infoValue="COLOR ROSA"></InputWithEdit>
        </div>
      </div>
    </>
  );
};

export default ProductDetail;
