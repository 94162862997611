import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import LogoFirma from '@/assets/img/icons/Logo-Firma.svg';

const Container = styled.div`
  position: relative;
  background: white;
  cursor: pointer;
  min-width:300px;
  max-width:360px;
`;

const ImageContainer = styled.div`
  width: 100%;
  min-width:300px
  max-width:360px;
  height: 450px;
`;

const Image = styled.img`
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
  min-width:300px;
  max-width:360px;
`;

const TopBarContainer = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  min-width:300px;
  max-width:360px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  /*background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 100%);*/

  & img {
    width: 50px;
  }
`;

const IconConatainer = styled.div`
  cursor: pointer;
`;

const CardProduct = ({ name, imgSrc, price, cName, itemId }) => {
  const numberFormat = value =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'MXN',
    }).format(value);

  const [iconState, setIconState] = useState(false);
  const buttonHandler = () => {
    setIconState(current => !current);
  };

  return (
    <Container className={`${cName} card`}>
      <TopBarContainer>
        <img src={LogoFirma} alt="" />
        <IconConatainer onClick={buttonHandler}>
          {iconState ? <FavoriteIcon /> : <FavoriteBorderIcon />}
        </IconConatainer>
      </TopBarContainer>
      <ImageContainer>
        <Link to={itemId}>
          <Image src={imgSrc} alt="" className="" />
        </Link>
      </ImageContainer>

      <div className="px-6 py-4">
        <div className="font-bold text-lg mb-2">{name}</div>
        <p className="text-gray-700 text-base">{numberFormat(price)}</p>
      </div>
      <div></div>
    </Container>
  );
};

export default CardProduct;
