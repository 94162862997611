import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import CardProductPrev from '@/components/Cards/CardProductPrev';
import HistorySlider from '@/components/Carousel/HistorySlider';
import NavBar from '@/components/NavBar/NavBar';
import Newsletter from '@/components/Newsletter';
import VerticalCarousel from '@/components/VerticalCarousel';
import { CollectionWinter } from '@/Data/data';

const ContainerStand2 = styled.section`
  position: relative;
  background-color: white;
`;

const ContainerStand1 = styled(ContainerStand2)``;

const MediaContainer = styled.div`
  height: auto;
`;
const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 1.2rem;
`;

const SubTitle = styled.h3`
  text-align: center;
  font-size: 1.2rem;
`;

const FirmContainer = styled.div`
  & h2 {
    font-size: 3rem;
  }

  & h3 {
    font-size: 2rem;
    line-height: 1rem;
    padding-left: 10rem;
  }
`;

const LaPerezIndex = () => {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        // console.error('Error attempting to play', error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <React.Fragment>
      <NavBar />
      <ContainerStand1>
        <MediaContainer>
          <video
            src={require('../assets/img/Video/HMR 21-05-05.mp4')}
            type="video/mp4"
            ref={videoEl}
            autoPlay
            controls
            preLoad="auto"
            loop
            muted
            width="100%"
            webkit-playsinline
            playsinline
          />
        </MediaContainer>
      </ContainerStand1>

      <HistorySlider></HistorySlider>
      <ContainerStand2
        id="highligth-stand-2"
        className="flex flex-col justify-center items-center gap-5 py-32 md:py-64"
      >
        <div className="title-article flex flex-col justify-center gap-4">
          <Title>Primavera 2022 Pre-colección</Title>
          <SubTitle>Para ella </SubTitle>
        </div>
        <VerticalCarousel photos={CollectionWinter}></VerticalCarousel>
        <FirmContainer id="artist-logo-nx">
          <Title>Najjat Harb</Title>
          <SubTitle>& Xavier Berg</SubTitle>
        </FirmContainer>
      </ContainerStand2>

      <section className="flex flex-wrap justify-center gap-10 py-5 md:py-20">
        {CollectionWinter.map((item, index) => {
          if (index >= 6) {
            return null;
          }
          return (
            <CardProductPrev
              key={item}
              item={item}
              cName=" w-3/4 sm:w-1/2 md:w-1/3 lg:w-1/5"
            ></CardProductPrev>
          );
        })}
      </section>
      <Newsletter></Newsletter>
    </React.Fragment>
  );
};

export default LaPerezIndex;
