import axios from 'axios';

import { publicRequest } from './requestMethods';


export const getAllProductList = async () => {
  try {
    const response = await publicRequest.get('/products');
    return response.data;
  } catch (error) {
    console.error('Error fetching companies:', error);
    throw error;
  }
};


async function getProductList(cat) {
  return await axios.get(
    cat ? `http://localhost:3001/api/products?category=${cat}` : 'http://localhost:3001/api/products',
  );
}

async function getProduct(id) {
  return await publicRequest.get(`products/find/product/${id}`);
}

async function getProductByCategory(category) {
  return await publicRequest.get(`products/by-category?categories=${category}`);
}

async function getColors(cat) {
  return await axios.get(
    cat ? `http://localhost:3001/api/products/colors?categories=${cat}` : 'http://localhost:3001/api/products',
  );
}

async function getSizes(cat) {
  return await axios.get(
    cat ? `http://localhost:3001/api/products/sizes?categories=${cat}` : 'http://localhost:3001/api/products',
  );
}

export { getProductList, getProduct,getProductByCategory, getColors, getSizes };