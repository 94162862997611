import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY

const accesstoken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzMDZjMjNkNWQzYzVkN2E0NTEzOWExNSIsImlzQWRtaW4iOnRydWUsImlhdCI6MTY2NDk4ODE5NiwiZXhwIjoxNjY1MjQ3Mzk2fQ.AWwQaZ-bR_ecxgpjixegoXZRNfICkTyEdQ2jgzR0bew';

export const publicRequest = axios.create({
  baseURL: BASE_URL,
    headers: {
    "Content-Type": "application/json"
  }
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  header: { token: `Bearer ${accesstoken}` },
});

export const userRequestByParams = (params = {}) => axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json"
  },
  params: params,
});