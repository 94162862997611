import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 2rem 2rem;
  background-color: #f6f5f3;
`;
const Title = styled.h1`
  font-size: 32px;
  margin: 30px 0;
  font-weight: bold;
  text-transform: uppercase;
`;

const Desc = styled.p`
  max-width: 450px;
  text-align: justify;
  margin: 0 0 30px 0;
`;

const EmailInput = styled.input`
  border: 1px solid black;
  padding: 0 10px; 
  height: 30px;
  width: 280px;
  flex: 5;
`;

const IconContainer = styled.div`
  heigth: 30px;
  width: 30px;
  border: 1px solid black;
  cursor: pointer;
  margin-right: 30px;

  &:hover {
    background-color: black;
    color: white;
  }
`;

function Newsletter() {
  return (
    <Container className="justify-center md:justify-between">
      <div className="flex flex-col">
        <Title>Unete #NAJJAT HARB</Title>
        <Desc>
          Regístrate y sé el primero en enterarte de novedades, promociones
          exclusivas y las últimas novedades del Mundo de Najjat Harb
        </Desc>
      </div>
      <div>
        <h3 lang="es">Correo electrónico*</h3>
        <div className="flex">
          <EmailInput placeholder="ejemplo@correo.com"></EmailInput>
          <IconContainer>
            <ArrowForwardIcon></ArrowForwardIcon>
          </IconContainer>
        </div>
      </div>
    </Container>
  );
}

export default Newsletter;
