import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

// Components
import Footer from '@/components/Footer/Footer';
import { SnackbarProvider } from './components/Alerts/SnackBarProvider';

// Pages
import AdminLoginPage from '@/pages/AdminLoginPage';
import Cart from '@/pages/Cart';
import CollectionPage from '@/pages/CollectionPage';
import Dashboard from '@/pages/Dashboard';
import DashboardProductDetails from '@/pages/DashboardProductDetails';
import FrontPage from '@/pages/FrontPage';
import FrontPage2 from '@/pages/FrontPage2';
import LaPerezIndex from '@/pages/LaPerezPage';
import LoginPage from '@/pages/LoginPage';
import Nosotros from '@/pages/NosotrosPage';
import ProductDetail from '@/pages/ProductDetail';
import ProductList from '@/pages/ProductsList';
import RegisterPage from '@/pages/RegisterPage';
import HomePage from './pages/HomePage';
import WebAdminPage from './pages/Admin/WebAdminPage';
import NotFoundPage from './pages/NotFoundPage';
import ProductAdminPage from './pages/Admin/ProductAdminPage';

const RoutesConfig = {
  public: [
    { path: "/", element: <HomePage /> },
    { path: "/register", element: <RegisterPage /> },
    { path: "/login", element: <LoginPage /> },
    { path: "/frontPage", element: <HomePage /> },
    { path: "/laPerez", element: <LaPerezIndex /> },
    { path: "/Nosotros", element: <Nosotros /> },
    { path: "/Women", element: <HomePage /> },
    { path: "/Men", element: <HomePage /> },
    { path: "/ProductList/:id", element: <ProductList /> },
    { path: "/ProductList/:id/:id", element: <ProductDetail /> },
    { path: "/Collections", element: <CollectionPage /> },
    { path: "/Cart", element: <Cart /> },
  ],
  admin: [
    { path: "/admin/", element: <WebAdminPage /> },
    { path: "/admin-webpage", element: <WebAdminPage /> },
    { path: "/admin-products", element: <ProductAdminPage /> },
    { path: "/admin/products/:id", element: <DashboardProductDetails /> },
    { path: "/admin/:id", element: <Navigate to="/" /> },
  ],
};

function App() {
  const user = useSelector((state) => state.user.currentUser);
  const location = useLocation();
  const adminLogin = user?.isAdmin || false;

  const hiddenNavFooterPaths = ['/admin', '/dashboard', '/admin-login'];
  const shouldShowNavFooter = !hiddenNavFooterPaths.some((path) => location.pathname.startsWith(path));

 // Correct iteration over arrays in RoutesConfig
const renderPublicRoutes = () =>
  RoutesConfig.public.map(({ path, element }) => (
    <Route key={path} path={path} element={element}/>
  ));

const renderAdminRoutes = () =>
  RoutesConfig.admin.map(({ path, element }) => (
    <Route
      key={path}
      path={path}
      element={true ? <SnackbarProvider>{element}</SnackbarProvider> : <NotFoundPage/>}
    />
  ));


  return (
    <>
      <Routes>
        {renderPublicRoutes()}
        {renderAdminRoutes()}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      {shouldShowNavFooter && <Footer />}
    </>
  );
}

export default App;
