import {Box,Button, Typography } from '@mui/material'
import React from 'react'



const ImageHomeCard = ({text='dressCustom', imgUrl }) => {
  return (
   <Box
  className={`flex justify-center items-center flex-col gap-5 w-full h-[750px] bg-cover bg-center ${imgUrl ? '' : 'bg-blue-500'}`}
  style={imgUrl ? { backgroundImage: `url(${imgUrl})` } : {}}
>
  <Typography 
    variant='h5' 
    component='h5' 
    className='font-[Ephesis] text-white shadow-md text-center'
  >
    {text}
  </Typography>
  <button className='button-23-reverse' variant="outlined">
    Shop Now
  </button>
</Box>



  )
}

export default ImageHomeCard