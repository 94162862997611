import { useState } from 'react'
import {Box, Button, Container, Typography } from '@mui/material'
import VerticalNavBar from '../../components/Admin/NavBar/VerticalNavBar'
import WebPagesTable from '../../components/Admin/Tables/WebPageTable'
import AddSliderModal from '../../components/Admin/Modal/WebPages/AddSliderModal'
import { createWebSlider } from '../../services/webPage'
import { useSnackbar } from '../../components/Alerts/SnackBarProvider'
import MobileAdminNavBar from '../../components/Admin/NavBar/MobileAdminNavBar'
import { Home, ShoppingCart, Person, Theaters } from '@mui/icons-material';
import DashboardCard from '../../components/Admin/DashboardCard'

export const Options = [
  {
    title: 'Hombres',
    icon: <Person />,
  },
  {
    title: 'Mujeres',
    icon: <Person />,
  },
  {
    title: 'Accesorios',
    icon: <ShoppingCart />,
  },
  {
    title: 'Inicio',
    icon: <Home />,
  },
];



const WebAdminPage = () => {

    const [selectOption,setSelectOption] = useState('Slider Principal');
    const [modalOpen,setModalOpen] = useState(false)
    const handleOption =(option) =>{
        setSelectOption(option);
    }
    const { showSnackbar } = useSnackbar();
  
      const getAdminTable =(option)=>{
        switch(option){
          case "Slider Principal":
            return <WebPagesTable category='Slider Principal'/>
        case "Promociones Iniciales":
            return <WebPagesTable category='Promociones Iniciales'/>
        case "Slider Planes":
            return <WebPagesTable category='Slider Planes'/>
        case "Promociones Vip":
            return <WebPagesTable category='Promociones Vip'/>
          default:
            return <WebPagesTable category='Slider Principal'/>
        }
      }


      const handleSaveWebSlider = async (slider ) => {
        // Handle saving the promotion data here
        try {

          const res = await createWebSlider(slider);
          showSnackbar('Se Agragado Slider Promocional de forma extiosa!', 'success');
          getAdminTable(selectOption);
        } catch (error) {
          console.log(error);
        }
      };
  return (<>
   <section className=' relative flex flex-col md:flex-row '>
    <div className="hidden md:block">
        <VerticalNavBar />
      </div>
      <div className="block md:hidden">
        <MobileAdminNavBar />
      </div>
    <Container>

    <div id="top-function-dashboard" className='flex flex-wrap justify-center gap-2 md:gap-5 py-5 md:py-5 ml-5 md:ml-10'>
      {Options.map((option,index)=>{
          return <Button key={index}onClick={() => handleOption(option.title)} className='text-black'><DashboardCard 
          title={option.title} 
          value={0}
          icon={option.icon}
          />
        </Button>
      })}
    </div>
    
      <div className="flex justify-center w-full">
        <div className='flex flex-col justify-center items-center gap-5 shadow-2xl w-10/12 rounded-xl py-10'>
          <div className='flex justify-between w-full'>
            <Typography variant='h5' className='text-center w-full'><strong>{selectOption}</strong></Typography>
            <Box className='flex w-full justify-end items-center gap-5 px-5'>
              <Button onClick={() => setModalOpen(true)} variant="contained" color="primary" className='!rounded-full'>
              Agregar Slider
              </Button>
              <AddSliderModal
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              onSave={handleSaveWebSlider}
              />
            </Box>
          </div>
          {getAdminTable(selectOption)}
        </div>
      </div>
    </Container>
</section>
  </>
  )
}

export default WebAdminPage